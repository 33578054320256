html {
  background-color: #fbeed7 !important;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.dogCardParent {
  position: relative;
}

.dogCard {
  object-fit: cover;
  width: 250px;
  height: 250px;
  border-radius: 5px;
  transition: 0.2s;
  background: rgb(0,0,0);
  background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 90%, rgba(2,0,36,1) 100%);
}

.dogCard:hover {
  transition: 0.2s;
  -webkit-filter: brightness(70%);
  cursor: pointer;
}

.dogCardName {
  color: white;
  background-color: #6f4326;
  position: absolute;
  top: 100%;
  left: 100%;
  transform: translate(-100%, -100%);
  font-size: large;
  font-weight: bold;
  border-radius: 5px;
  padding: 2px 4px 2px 4px;
  opacity: .8;
}

.dogVoteBox {
  position: absolute;
  top: 100%;
  transform: translate(0%, -100%);
  display: flex;
}

.dogUpvote{
  color: white;
  background-color: #28a745 !important;
  border-color: rgba(0, 0, 0, 0) !important;
  height: 30px;
  font-size: medium;
  font-weight: bold;
  border-radius: 5px;
  padding: 2px 4px 2px 4px;
  cursor: pointer;
}

.dogDownvote{
  color: white;
  background-color: #dc3545 !important;
  border-color: rgba(0, 0, 0, 0) !important;
  height: 30px;
  transform: scaleY(-1);
  font-size: medium;
  font-weight: bold;
  border-radius: 5px;
  padding: 2px 4px 2px 4px;
  cursor: pointer;
}

.dogScore{
  padding: 2px 4px 2px 4px;
  color: white;
  background-color: #6f4326;
  opacity: .8;
  border-radius: 5px;
  font-weight: bold;
}

.grow {
  flex-grow: 1;
}

.grow-3 {
  flex-grow: 3;
}

.dogButton {
  background-color: #6f4326 !important;
  border-color: initial ! important;
}

.specialModalHeader {
  padding: 0px !important;
}

.specialModalHeader img {
  border-radius: 5px;
}

.keyword {
  transition: 0.5s;
  border: 1px solid #6f4326;
  border-radius: 20px;
}

.keyword:hover {
  transition: 0.5s;
  background-color: #6f4326;
  color: white;
  cursor: pointer;
}

.topSearch {
  border-radius: 15px !important;
  background-color: rgb(233, 233, 233) !important;
  color: rgb(209, 209, 209);
  border: 0px solid black;
}